import { APPLE_PAY } from "src/constants/applepay.constants";
import { GOOGLE_PAY } from "src/constants/googlepay.constants";
import { InvalidPaymentModeOption, MissingInitializeOptions } from "src/exceptions/initialize";
import { initializeAlternativeSession } from "src/initialize/initialize-alternative";
import { InternalDataStoreClass } from "src/public/data-store";
import { AlternatePaymentOptions } from "src/types/alternative-opts.types";

export const initialize = (options: AlternatePaymentOptions) => {
  if (
    !options 
    || !options["button-selector"] 
    || !options.countryCode 
    || !options.currencyCode 
    || !options.merchantIdentifier
    || !options.merchantName
    || !options.txnAmount
    || !options.mode
  ) {
    new MissingInitializeOptions().throw();
  }

  if (
    options.mode 
    && options.mode !== GOOGLE_PAY 
    && options.mode !== APPLE_PAY
  ) {
    new InvalidPaymentModeOption().throw();
  }

  const dataStore = new InternalDataStoreClass();
  dataStore.setOptions(options);
  return initializeAlternativeSession(dataStore);
}