/* eslint-disable @typescript-eslint/no-explicit-any */
import { InternalDataStoreClass } from "src/public/data-store";
import { PRGooglePayMethod } from "src/types/payment-request-opts.enum";
import { ALLOWED_PAYMENT_METHODS_TYPE, GATEWAY_CELERISPAY, SUPPORTED_AUTH_METHODS, SUPPORTED_CARD_NETWORKS, TOKENIZATION_TYPE } from "src/constants/googlepay.constants";
import GooglePayButton from "@google-pay/button-element";

export class GooglepayAPI {
  onPaymentAuthorized: () => void;
  onClick: () => void;
  buttonId = 'cf-googlepay-express-checkout';

  constructor(private dataStore: InternalDataStoreClass) {}

  buttonProps() {
    return {
      buttonType: 'buy',
      buttonLocale: 'en',
      buttonSizeMode: 'fill',
      paymentRequest: {
        ...this.generateMethod(),
        callbackIntents: ['PAYMENT_AUTHORIZATION'],
      },
      existingPaymentRequired: false,
      onPaymentAuthorized: this.onPaymentAuthorized,
      onclick: this.onClick,
    };
  }

  protected generateMethod() {
    return {
      [PRGooglePayMethod.API_VERSION]: 2,
      [PRGooglePayMethod.API_VERSION_MINOR]: 0,
      [PRGooglePayMethod.ALLOWED_PAYMENT_METHODS]: [{
        [PRGooglePayMethod.TYPE]: ALLOWED_PAYMENT_METHODS_TYPE,
        [PRGooglePayMethod.PARAMETERS]: {
          [PRGooglePayMethod.ALLOWED_AUTH_METHODS]: SUPPORTED_AUTH_METHODS,
          [PRGooglePayMethod.ALLOWED_CARD_NETWORKS]: SUPPORTED_CARD_NETWORKS,
        },
        [PRGooglePayMethod.TOKENIZATION_SPECIFICATIONS]: {
          [PRGooglePayMethod.TYPE]: TOKENIZATION_TYPE,
          [PRGooglePayMethod.PARAMETERS]: {
            [PRGooglePayMethod.GATEWAY]: GATEWAY_CELERISPAY,
            [PRGooglePayMethod.GATEWAY_MERCHANT_ID]: this.dataStore.getAPIKey(),
          }
        },
      }],
      [PRGooglePayMethod.TRANSACTION_INFO]: {
        [PRGooglePayMethod.TOTAL_PRICE_STATUS]: 'FINAL',
        [PRGooglePayMethod.TOTAL_PRICE_LABEL]: 'Total',
        [PRGooglePayMethod.TOTAL_PRICE]: this.dataStore.getTxnAmount().toString(),
        [PRGooglePayMethod.CURRENCY_CODE]: this.dataStore.getCurrencyCode(),
        [PRGooglePayMethod.COUNTRY_CODE]: this.dataStore.getCountryCode(),
      },
      [PRGooglePayMethod.MERCHANT_INFO]: {
        [PRGooglePayMethod.MERCHANT_NAME]: this.dataStore.getMerchantName(),
        [PRGooglePayMethod.MERCHANT_ID]: this.dataStore.getMerchantIdentifier(),
      },
    }
  }

  generateGooglePayButton() {
    const environment = this.dataStore.isTestMode() ? 'TEST' : 'PRODUCTION';
    const button = new GooglePayButton();
    button.id = this.buttonId;
    button.environment = environment;
    Object.assign(button, this.buttonProps());
    const userButtonSelector = this.dataStore.getButtonSelector();
    userButtonSelector.prepend(button);
  }

  setOnClick(callback: () => void) {
    this.onClick = callback;
  }
  setOnPaymentAuthorized(callback: () => void) {
    this.onPaymentAuthorized = callback;
  }
}