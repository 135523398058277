import { InternalDataStoreClass } from "src/public/data-store";
import { PRPayerOpts, PRPaymentDetails } from "src/types/payment-request-opts.enum";
import { PaymentRequestSession } from "src/types/payment-request-session";

export abstract class AbstractPRAPI {

  abstract buttonId: string;
  protected abstract prepareStyle(): string;
  dataStore: InternalDataStoreClass;

  private generatePaymentOptions() {
    return {
      [PRPayerOpts.REQUEST_PAYER_NAME]: this.dataStore.getRequestPayerName() || false,
      [PRPayerOpts.REQUEST_PAYER_EMAIL]: this.dataStore.getRequestPayerEmail() || false,
    }
  }

  protected generatePaymentDetails() {
    return {
      [PRPaymentDetails.TOTAL]: {
        [PRPaymentDetails.LABEL]: this.dataStore.getMerchantName(),
        [PRPaymentDetails.TXN_AMOUNT]: {
          [PRPaymentDetails.TXN_AMOUNT_VALUE]: this.dataStore.getTxnAmount(),
          [PRPaymentDetails.CURRENCY_CODE]: this.dataStore.getCurrencyCode(),
        },
      },
    }
  }

  initializePRSession(): PaymentRequestSession {
    if (!window.PaymentRequest) { throw new Error('PaymentRequest not available'); }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    /* @ts-ignore */
    return new PaymentRequest([this.generateMethod()], this.generatePaymentDetails(), this.generatePaymentOptions());
  }

  initializeButton(): void {
    const localStore = this.dataStore;
    const buttonId = this.buttonId;
    const styles = this.prepareStyle();
    this.initializePRSession().canMakePayment()
    .then(function(isAvailable: boolean){
      if(isAvailable) {
        const selector: HTMLElement = localStore.getButtonSelector();
        if (!document.getElementById(buttonId)){
          const button = document.createElement('button');
          button.id = buttonId;
          button.type = 'button';
          button.setAttribute('style', styles);
          selector.prepend(button);
        }
      }
    })
  }

  getButtonId(): string {
    return this.buttonId;
  }
}