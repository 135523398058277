export abstract class AbstractException {
  public abstract errorCode: number;
  public abstract errorMessage: string;
  public abstract errorDescription?: string;

  public stringifyError() {
    const { errorCode, errorMessage, errorDescription } = this;
    return `${errorMessage}
${errorDescription ? `
Description: ${errorDescription}` : ''}

${JSON.stringify({
      errorCode,
      errorMessage,
      errorDescription,
    }, undefined, 2)}`;
  }

  public throw() {
    throw this.stringifyError();
  }
}
