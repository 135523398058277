import { MERCHANT_CAPABILITIES, SUPPORTED_METHODS, SUPPORTED_NETWORKS } from "src/constants/applepay.constants";
import { AbstractPRAPI } from "src/public/abstract-pr-api";
import { InternalDataStoreClass } from "src/public/data-store";
import { PRApplepayMethod } from "src/types/payment-request-opts.enum";

export class ApplepayPaymentRequestAPI extends AbstractPRAPI {
  buttonId = 'cf-applepay-express-checkout'; 

  constructor(dataStore: InternalDataStoreClass) {
    super();
    this.dataStore = dataStore;
  }
  
  protected prepareStyle() {
    return `
      -webkit-appearance: -apple-pay-button;
      -apple-pay-button-type: check-out;
      width: 100%;
      margin-bottom: 20px;
      padding: 24px;   
      border-radius: 20px;
    `;
  }

  protected generateMethod() {
    return {
      [PRApplepayMethod.SUPPORTED_METHODS]: SUPPORTED_METHODS,
      [PRApplepayMethod.DATA]: {
        [PRApplepayMethod.VERSION]: 12,
        [PRApplepayMethod.MERCHANT_IDENTIFIER]: this.dataStore.getMerchantIdentifier(),
        [PRApplepayMethod.MERCHANT_CAPABILITIES]: MERCHANT_CAPABILITIES,
        [PRApplepayMethod.SUPPORTED_NETWORKS]: SUPPORTED_NETWORKS,
        [PRApplepayMethod.COUNTRY_CODE]: this.dataStore.getCountryCode(),
      },
    }
  }
}