import { ApplepayPaymentRequestAPI } from "src/public/applepay-pr-api"
import { InternalDataStoreClass } from "src/public/data-store";
import { GooglepayAPI } from "src/public/googlepay-api";
import { GooglepayPaymentRequestAPI } from "src/public/googlepay-pr-api";
import { PaymentRequestMerchantValidation, PaymentRequestSession } from "src/types/payment-request-session";

class InitializeAlternative {
  buttonId: string;
  alternativePRAPI: PaymentRequestSession;
  googlepayAPI: GooglepayAPI;
  dataStore: InternalDataStoreClass;

  constructor(dataStore: InternalDataStoreClass) {
    let paymentReqAPI;
    this.dataStore = dataStore;
    if(dataStore.isApplepay()) {
      paymentReqAPI = new ApplepayPaymentRequestAPI(dataStore);
      paymentReqAPI.initializeButton();
      this.alternativePRAPI = paymentReqAPI.initializePRSession();
      this.buttonId = paymentReqAPI.getButtonId();
    } else {
      this.googlepayAPI = new GooglepayAPI(dataStore);
    }
  }

  openAlternativePopup(): Promise<unknown> {
    return this.alternativePRAPI.show();
  }

  onMerchantValidation(callback: (event: PaymentRequestMerchantValidation) => void): void {
    this.alternativePRAPI.onmerchantvalidation = callback;
  }

  getButtonId(): string {
    return this.buttonId;
  }

  onPaymentAuthorized(callback: () => void): void {
    this.googlepayAPI.setOnPaymentAuthorized(callback);
  }

  onClick(callback: () => void): void {
    this.googlepayAPI.setOnClick(callback);
  }

  generateGooglePayButton (): void {
    this.googlepayAPI.generateGooglePayButton();
  }

}

export const initializeAlternativeSession = (dataStore: InternalDataStoreClass): InitializeAlternative => {
  return new InitializeAlternative(dataStore);
}