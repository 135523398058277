export enum PRPayerOpts {
  REQUEST_PAYER_EMAIL = "requestPayerName",
  REQUEST_PAYER_NAME = "requestPayerEmail",
}

export enum PRPaymentDetails {
  TOTAL = "total",
  LABEL = "label",
  TXN_AMOUNT = "amount",
  TXN_AMOUNT_VALUE = "value",
  CURRENCY_CODE = "currency",
}

export enum PRApplepayMethod {
  SUPPORTED_METHODS = "supportedMethods",
  DATA = "data",
  VERSION = "version",
  MERCHANT_IDENTIFIER = "merchantIdentifier",
  MERCHANT_CAPABILITIES = "merchantCapabilities",
  SUPPORTED_NETWORKS = "supportedNetworks",
  COUNTRY_CODE = "countryCode"
}

export enum PRGooglePayMethod {
  SUPPORTED_METHODS = "supportedMethods",
  DATA = "data",
  VERSION = "version",
  MERCHANT_IDENTIFIER = "merchantIdentifier",
  MERCHANT_ID = "merchantId",
  MERCHANT_CAPABILITIES = "merchantCapabilities",
  SUPPORTED_NETWORKS = "supportedNetworks",
  COUNTRY_CODE = "countryCode",
  CURRENCY_CODE = "currencyCode",
  ENVIRONMENT = "environment",
  API_VERSION = "apiVersion",
  API_VERSION_MINOR = "apiVersionMinor",
  MERCHANT_NAME = "merchantName",
  MERCHANT_INFO = "merchantInfo",
  ALLOWED_PAYMENT_METHODS = "allowedPaymentMethods",
  TYPE = "type",
  PARAMETERS = "parameters",
  ALLOWED_AUTH_METHODS = "allowedAuthMethods",
  ALLOWED_CARD_NETWORKS = "allowedCardNetworks",
  TOKENIZATION_SPECIFICATIONS = "tokenizationSpecification",
  GATEWAY = "gateway",
  GATEWAY_MERCHANT_ID = "gatewayMerchantId",
  TRANSACTION_INFO = "transactionInfo",
  TOTAL_PRICE_STATUS = "totalPriceStatus",
  TOTAL_PRICE_LABEL = "totalPriceLabel",
  TOTAL_PRICE = "totalPrice",

}
