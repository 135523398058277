import { AbstractException } from "src/exceptions/abstract.exception";

export class MissingInitializeOptions extends AbstractException {
  public errorCode = 1001;
  public errorMessage = "'initialize' called without options object";
  public errorDescription = "Please provide initialization options when calling 'initialize' method";
}
export class InvalidPaymentModeOption extends AbstractException {
  public errorCode = 1002;
  public errorMessage = "Invalid value in 'options.mode'";
  public errorDescription = "'mode' can only have values 'googlepay' or 'applepay'";
}