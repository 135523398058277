export const SUPPORTED_AUTH_METHODS = ['PAN_ONLY', 'CRYPTOGRAM_3DS'];

export const SUPPORTED_METHODS = 'https://google.com/pay';

export const SUPPORTED_CARD_NETWORKS = ['MASTERCARD', 'VISA'];

export const GATEWAY_CELERISPAY = 'celerispay';

export const TOKENIZATION_TYPE = 'PAYMENT_GATEWAY';

export const ALLOWED_PAYMENT_METHODS_TYPE = 'CARD';

export const GOOGLE_PAY = 'googlepay';
