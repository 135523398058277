import { APPLE_PAY } from "src/constants/applepay.constants";
import { AlternatePaymentOptions } from "src/types/alternative-opts.types";

type InternalDataStore = {
  options?: Partial<AlternatePaymentOptions>;
}

export class InternalDataStoreClass {
  private dataStore: InternalDataStore = {};

  setOptions(options: AlternatePaymentOptions) {
    this.dataStore.options = { ...options };
    return this;
  }
  getMerchantIdentifier() {
    return this.dataStore.options.merchantIdentifier;
  }
  getMerchantName() {
    return this.dataStore.options.merchantName;
  }
  getButtonSelector() {
    return this.dataStore.options['button-selector'];
  }
  getCountryCode() {
    return this.dataStore.options.countryCode;
  }
  getCurrencyCode() {
    return this.dataStore.options.currencyCode;
  }
  getTxnAmount() {
    return this.dataStore.options.txnAmount;
  }
  getRequestPayerName() {
    return this.dataStore.options.requestPayerName;
  }
  getRequestPayerEmail() {
    return this.dataStore.options.requestPayerEmail;
  }
  isTestMode() {
    return this.dataStore.options.testMode;
  }
  getAPIKey() {
    return this.dataStore.options.apiKey;
  }
  isApplepay() {
    return this.dataStore.options.mode === APPLE_PAY;
  }
}
